import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import ContactInfoModal from "./ContactInfo";
import ButtonPrimary from "./misc/ButtonPrimary";
import ButtonOutline from "./misc/ButtonOutline";

const ContactPopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [isClosed, setIsClosed] = useState(false); // Track manual close
  const [showContact, setShowContact] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const counselorDiv = document.getElementById("counselor"); // Adjust the ID
      if (counselorDiv) {
        const rect = counselorDiv.getBoundingClientRect();
        if (rect.top < window.innerHeight && !isClosed) {
          setShowPopup(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isClosed]);

  return showPopup && !isClosed ? (
    <>
      <ContactInfoModal isOpen={showContact} setIsOpen={setShowContact} />
      <motion.div
        initial={{ opacity: 0, scale: 0.9, y: 50 }}
        animate={{ opacity: 1, scale: 1, y: 0 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
        className="fixed bottom-20 mx-2 sm:mx-0  sm:bottom-10 sm:right-10 bg-white-300 text-white p-4 rounded-lg shadow-lg flex items-center space-x-4"
      >
        <button
          onClick={() => setIsClosed(true)}
          className="text-white hover:text-gray-300"
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 512 512"
            height="24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"></path>
          </svg>
        </button>
        <p className="text-sm">Nog vragen? We helpen graag!</p>
        <ButtonOutline
          onClick={() => {
            setShowContact(true);
            setIsClosed(true);
          }}
          className={"whitespace-nowrap"}
        >
          Contacteer ons
        </ButtonOutline>

        {/* Close button */}
      </motion.div>
    </>
  ) : (
    <>
      <ContactInfoModal isOpen={showContact} setIsOpen={setShowContact} />
    </>
  );
};

export default ContactPopup;
