import Image from "next/image";
import React, { useMemo } from "react";
import { motion } from "framer-motion";
import getScrollAnimation from "../utils/getScrollAnimation";
import ScrollAnimationWrapper from "./Layout/ScrollAnimationWrapper";
import ButtonPrimary from "./misc/ButtonPrimary";
import ButtonOutline from "./misc/ButtonOutline";
import ContactInfoModal from "./ContactInfo";

const features = [
  // "Samenwerken met andere zorgorganisaties",
  // "Efficiëntere opvolging van patiënten",
  // "Houvast voor patiënten",
  "Geïntegreerde zorg",
  "Doorstroom van het signaleringsplan",
  "Ontkoppel vorige begeleiders, koppel nieuwe begeleiders",
];

const Feature = () => {
  const scrollAnimation = useMemo(() => getScrollAnimation(), []);
  const [contactInfoOpen, setContactInfoOpen] = React.useState(false);

  return (
    <div
      className="sm:py-8 max-w-screen-xl mt-8 mb-6 sm:mt-14 sm:mb-14 px-6 sm:px-8 lg:px-16 mx-auto"
      id="network"
    >
      <div className="grid grid-flow-row sm:grid-flow-col grid-cols-1 sm:grid-cols-2 gap-8 p  y-8 sm:my-12">
        <ScrollAnimationWrapper className="content-center order-2 sm:order-1">
          <motion.div
            className="flex flex-col items-center mx-auto w-full lg:w-9/12"
            variants={scrollAnimation}
          >
            {/* <div className="gap-8 p  y-8 my-12 mb-24 items-center"> */}

            {/* <div className="gap-8 p  y-8 my-12 mb-24">
                <ScrollAnimationWrapper>
                <motion.div
                    className="flex flex-col items-center justify-center mx-auto w-full lg:w-2/5"
                    variants={scrollAnimation}
                > */}
            <h3 className="text-2xl lg:text-2xl font-medium leading-relaxed text-black-600">
              Algemeen directeur Sint-Jozef Pittem
            </h3>
            <p className="my-2 ml-2 text-black-500 italic">
              We hopen dat in de toekomst andere GGZ-organisaties ook met de app
              aan de slag gaan. Want het is belangrijk voor de patiënt én
              context dat er netwerkgericht gedacht én gehandeld wordt zodat de
              patiënt op een afgestemde manier verder kan ondersteund worden
            </p>

            <a
              href="https://www.sintjozefpittem.be/nieuws/kliniek-sint-jozef-vertaalt-vroegsignaleringsplan-handige-app"
              target="_blank"
              className="my-2 text-black-500"
            >
              Lees meer
            </a>
            {/* </motion.div>
                </ScrollAnimationWrapper>
            </div> */}

            {/* </div> */}
          </motion.div>
        </ScrollAnimationWrapper>
        <ScrollAnimationWrapper className="content-center order-1 sm:order-2">
          <motion.div
            className="flex flex-col items-end mx-auto w-full lg:w-9/12"
            variants={scrollAnimation}
          >
            <h3 className="text-3xl lg:text-4xl font-medium leading-relaxed text-black-600">
              Netwerkgericht de app gebruiken
            </h3>
            <p className="my-2 text-black-500">
              {/* Voor organisaties is er een tool voorzien om het gebruik van de
              app binnen de organisatie soepel te laten verlopen. */}
            </p>
            <ul className="text-black-500 self-start list-inside ml-8">
              {features.map((feature, index) => (
                <motion.li
                  className="relative circle-check custom-list"
                  custom={{ duration: 2 + index }}
                  variants={scrollAnimation}
                  key={feature}
                  whileHover={{
                    scale: 1.1,
                    transition: {
                      duration: 0.2,
                    },
                  }}
                >
                  {feature}
                </motion.li>
              ))}
            </ul>
          </motion.div>
        </ScrollAnimationWrapper>
      </div>
      {/* <div className="grid grid-flow-row sm:grid-flow-col grid-cols-1 sm:grid-cols-2 gap-8 p  y-8 my-36">
        <ScrollAnimationWrapper className="content-center">
          <motion.div
            className="flex flex-col content-center justify-center ml-auto w-full lg:w-9/12"
            variants={scrollAnimation}
          >
            <h3 className="text-2xl lg:text-3xl text-center font-medium leading-relaxed text-black-600">
              Gebruik Sipi in jouw organisatie
            </h3>
            <p className="my-2 text-center text-black-500">
            Plan een vrijblijvend gesprek.
            </p>
          </motion.div>
        </ScrollAnimationWrapper>
        <ScrollAnimationWrapper className="content-center">
          <motion.div
            className="flex ml-auto w-full lg:w-9/12"
            variants={scrollAnimation}
          >
            <ButtonPrimary onClick={() => setContactInfoOpen(true)}>
              Contacteer ons
            </ButtonPrimary>
            <ContactInfoModal
              isOpen={contactInfoOpen}
              setIsOpen={setContactInfoOpen}
            />
          </motion.div>
        </ScrollAnimationWrapper>
      </div> */}

      {/* <div className="y-8 my-36">
        <ScrollAnimationWrapper className="flex justify-center w-full">
          <motion.div
            className="flex flex-row items-center content-center justify-center w-full lg:w-9/12"
            variants={scrollAnimation}
          >
            <div>
              <h3 className="text-2xl lg:text-3xl text-center font-medium leading-relaxed text-black-600">
                Gebruik Sipi in jouw organisatie
              </h3>
              <p className="my-2 text-center text-black-500">
                Plan een vrijblijvend gesprek.
              </p>
            </div>
            <div className="ml-8">
              <ButtonPrimary onClick={() => setContactInfoOpen(true)}>
                Contacteer ons
              </ButtonPrimary>
              <ContactInfoModal
                isOpen={contactInfoOpen}
                setIsOpen={setContactInfoOpen}
              />
            </div>
          </motion.div>
        </ScrollAnimationWrapper>
      </div> */}

      <div className="my-20 sm:my-24 flex justify-center">
        <ScrollAnimationWrapper className="flex justify-center w-full">
          <motion.div
            className="flex flex-col items-center text-center px-6 py-6 w-full lg:w-9/12 rounded-lg"
            variants={scrollAnimation}
          >
            <h3 className="text-2xl lg:text-3xl font-medium leading-relaxed text-black-600">
              Gebruik Sipi in jouw organisatie
            </h3>
            <p className="my-2 text-black-500">
              Plan een vrijblijvend gesprek.
            </p>
            <ButtonPrimary
              onClick={() => setContactInfoOpen(true)}
              addClass="mt-4"
            >
              Contacteer ons
            </ButtonPrimary>
            <ContactInfoModal
              isOpen={contactInfoOpen}
              setIsOpen={setContactInfoOpen}
            />
          </motion.div>
        </ScrollAnimationWrapper>
      </div>
    </div>
  );
};

export default Feature;
