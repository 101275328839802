import Image from "next/image";
import React, { useMemo } from "react";
import { motion } from "framer-motion";
import getScrollAnimation from "../utils/getScrollAnimation";
import ScrollAnimationWrapper from "./Layout/ScrollAnimationWrapper";

const features = [
  // "Geen opvolging van de oefening nodig. Snel overzicht tijdens de sessie",
  // maak duidelijk dat je de antwoorden niet moet nakijken als voorbereiding van de sessie
  // "Geen voorbereiding nodig voor de sessie.",
  // "Geen opvolging van de oefening nodig buiten de contactmomenten.",
  "Snelle overzichten bespreekbaar tijdens de contactmomenten",
  "Het signaleringsplan van je patiënten eenvoudig opstellen of aanpassen",
  "Inzicht in de spanningsopbouw van je patiënten",
];

const Feature = () => {
  const scrollAnimation = useMemo(() => getScrollAnimation(), []);

  return (
    <div
      className="max-w-screen-xl mt-8 mb-6 sm:mt-14 sm:mb-14 px-6 sm:px-8 lg:px-16 mx-auto"
      id="counselor"
    >
      <div className="grid grid-flow-row sm:grid-flow-col grid-cols-1 sm:grid-cols-2 gap-8 p  y-8 my-12">
        <ScrollAnimationWrapper className="flex w-full order-2 sm:order-1">
          <motion.div className="h-full mx-auto p-4" variants={scrollAnimation}>
            <Image
              src="/assets/Illustration4.png"
              alt="Overzicht van de fases, acties en notities"
              // layout="responsive"
              quality={100}
              height={2546/4}
              width={1175.8/4}
            />
          </motion.div>
        </ScrollAnimationWrapper>
        <ScrollAnimationWrapper className="content-center order-1 sm:order-2">
          <motion.div
            className="flex flex-col items-end mx-auto w-full lg:w-9/12"
            variants={scrollAnimation}
          >
            <h3 className="text-3xl lg:text-4xl font-medium leading-relaxed text-black-600">
              Als begeleider de app gebruiken
            </h3>
            {/* <p className="my-2 text-black-500">
              Met de smartphone en web app krijg je toegang tot het signaleringsplan van je patiënt. Het overzicht kan de
              sessie tot zijn essentie brengen, "Ik zie dat je het moeilijk had
              vorige week".
            </p> */}
            <ul className="my-4 text-black-500 self-start list-inside ml-8">
              {features.map((feature, index) => (
                <motion.li
                  className="relative circle-check custom-list"
                  custom={{ duration: 2 + index }}
                  variants={scrollAnimation}
                  key={feature}
                  whileHover={{
                    scale: 1.1,
                    transition: {
                      duration: 0.2,
                    },
                  }}
                >
                  {feature}
                </motion.li>
              ))}
            </ul>
          </motion.div>
        </ScrollAnimationWrapper>
      </div>
      <div className="grid grid-flow-row sm:grid-flow-col grid-cols-1 sm:grid-cols-2 gap-8 p  y-8 my-12">
        <ScrollAnimationWrapper className="content-center">
          <motion.div
            className="flex flex-col items-center mx-auto w-full lg:w-9/12"
            variants={scrollAnimation}
          >
            <h3 className="text-2xl lg:text-2xl font-medium leading-relaxed text-black-600">
              Verpleegkundige Annemie deelt haar ervaring in het Open Venster
              magazine
            </h3>
            <p className="my-2 text-black-500 italic">
              {/* Het vroegsignaleringsplan is door deze app niet langer een
              vergeeld blad papier aan de koelkast. Vroeger maakte een patiënt
              een plan op omdat wij benadrukten dat het belangrijk was. Een
              aantal hield het vol, maar bij de meesten vervaagden de intenties.
              Ze voelden zich iets beter, het scoren werd minder belangrijk en
              de gewoonte verdween. Het gegeven van de app zorgt voor een lagere
              drempel waardoor ze er mee bezig zijn. */}
              Wanneer de app goed gebruikt wordt, geeft het je als begeleider
              een beter zicht op de <span className="highlight">spanningsopbouw van een patiënt</span>. Bij een
              gesprek of een plan op papier moet een patiënt woorden kunnen
              geven aan een situatie of is het omslachtig om terug te kijken. De
              app schetst een <span className="highlight">objectieve weergave van de situatie</span>. Omgekeerd is
              het ook boeiend om te zien hoe een patiënt een crisis scoorde.
              Werd deze ook aangeduid als een rode fase of bleef de patiënt
              zichzelf in oranje scoren?
            </p>
            <a className="my-2 text-black-500" href="https://www.flipsnack.com/CABE9699E8C/2024-open-venster-58/full-view.html?p=6" target="_blank">Lees meer</a>
          </motion.div>
        </ScrollAnimationWrapper>
        <ScrollAnimationWrapper className="flex w-full justify-end">
          <motion.div className="h-full mx-auto p-4" variants={scrollAnimation}>
            <Image
              src="/assets/Illustration5.png"
              alt="Filter op crisis, datum of meer"
              // layout="responsive"
              quality={100}
              height={2550}
              width={2044}
            />
          </motion.div>
        </ScrollAnimationWrapper>
      </div>
    </div>
  );
};

export default Feature;
